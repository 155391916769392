import Vue from 'vue'
import AccountType from '@/constants/user/AccountType'

const accountTypes = [
  AccountType.USER_ACCOUNT_SIMPLE,
  AccountType.USER_ACCOUNT_PREMIUM_BRONZE,
  AccountType.USER_ACCOUNT_PREMIUM_SILVER,
  AccountType.USER_ACCOUNT_PREMIUM_GOLD,
  AccountType.USER_ACCOUNT_PREMIUM_PLATINUM,
]

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      statuses: [
        {
          className: 'na',
          icon: null,
          title: 'N/A',
        },
        {
          className: 'bronze',
          icon: 'premium-bronze-new',
          title: 'Bronze',
        },
        {
          className: 'silver',
          icon: 'premium-silver-new',
          title: 'Silver',
        },
        {
          className: 'gold',
          icon: 'premium-gold-new',
          title: 'Gold',
        },
        {
          className: 'platinum',
          icon: 'premium-platinum-new',
          title: 'Platinum',
        },
      ],
      values: [
        {
          title: 'Amount of TIME',
          type: 'amount',
          items: accountTypes.map(({ amount }) => Array.isArray(amount) ? `${amount[0]}-${amount[1]}` : `>=${amount}`)
        },
        {
          title: 'Return of commission for finished Jobs/Gigs',
          type: 'commission',
          items: accountTypes.map(({ returnCommission }) => `${returnCommission}%`)
        },
        {
          title: 'Referral Bonuses ',
          type: 'bonuses',
          items: accountTypes.map(({ referralBonuses }) => `${referralBonuses}%`)
        },
        {
          title: 'Visually Highlighted Provider',
          type: 'provider',
          items: accountTypes.map(({ highlighted }) => highlighted)
        },
        {
          title: 'Priority Placement points ',
          type: 'points',
          items: accountTypes.map(({ priority }) => priority)
        },
      ],
    }
  },
})
