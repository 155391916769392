import Vue from 'vue'
import canonicalMixin from '@/mixins/canonicalMixin'
import AimsSection from './AimsSection/AimsSection.vue'
import IncreasingSection from './IncreasingSection/IncreasingSection.vue'
import MainSection from './MainSection/MainSection.vue'
import WhatAreSection from './WhatAreSection/WhatAreSection.vue'
import WhatIsSection from './WhatIsSection/WhatIsSection.vue'

const metaDesc = 'Job Mining is a new feature on LaborX that provides rewards to Freelancers and Customers, paid in TIME tokens.'

export default Vue.extend<any, any, any, any>({
  mixins: [canonicalMixin],
  components: {
    AimsSection,
    IncreasingSection,
    MainSection,
    WhatAreSection,
    WhatIsSection,
  },
  metaInfo () {
    return {
      title: 'Job Mining',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: metaDesc
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Job Mining',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: metaDesc
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
      ],
      link: [this.canonicalLink],
    }
  },
})
