import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import { BUTTON_BLUE_LARGE } from '@/constants/components/button'
import { DASHBOARD } from '@/constants/routes'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      BUTTON_BLUE_LARGE,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    })
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onStartClick () {
      if (this.isLoggedIn) {
        this.$router.push({ name: DASHBOARD })
      } else {
        this.openModal({
          component: 'lx-sign-up-modal'
        })
      }
    },
  }
})
