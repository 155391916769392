import Vue from 'vue'
import debounce from 'lodash/debounce'

import PremiumTable from '@/partials/PremiumTable/PremiumTable.vue'
import jobMiningMixin from '../jobMiningMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [jobMiningMixin],
  components: {
    PremiumTable,
  },
})
