import Vue from 'vue'
import debounce from 'lodash/debounce'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      activeStep: 0,
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    alignProject (behavior?: 'smooth' | 'auto') {
      const projectsContainer = this.$el.querySelector('.aim-list')
      if (projectsContainer) {
        const cardScrolled = Math.floor(projectsContainer.scrollLeft / projectsContainer.clientWidth)
        let toCard
        if (window.innerWidth > 599) {
          const maxScrolled = projectsContainer.clientWidth / 3 + 10
          toCard = (projectsContainer.scrollLeft - cardScrolled * projectsContainer.clientWidth) > maxScrolled
            ? cardScrolled + 2
            : cardScrolled
        } else {
          const maxScrolled = projectsContainer.clientWidth / 2 + 10
          toCard = (projectsContainer.scrollLeft - cardScrolled * projectsContainer.clientWidth) > maxScrolled
            ? cardScrolled + 1
            : cardScrolled
        }
        this.scrollTo(toCard, behavior)
      }
    },
    scrollTo (slide: number, behavior?: 'smooth' | 'auto') {
      const projectsContainer = this.$el.querySelector('.aim-list')
      if (projectsContainer) {
        const step = slide % 3
        projectsContainer.scrollTo({ left: projectsContainer.clientWidth * step + (step * 20), behavior })
        this.activeStep = step
      }
    },
    onResize: debounce(function (this: any) { this.alignProject('auto') }, 30),
    onScroll: debounce(function (this: any) { this.alignProject('smooth') }, 30),
  }
})
