var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root premium-table"},[_c('div',{staticClass:"desktop"},[_c('div',{staticClass:"advantages desktop-view"},[_c('div',{staticClass:"item empty"}),_vm._l((_vm.statuses),function(ref,index){
var _obj;

var className = ref.className;
var title = ref.title;
var icon = ref.icon;return _c('div',{key:("item-main-title-" + index),staticClass:"item header",class:( _obj = {}, _obj[className] = true, _obj['top-left-corner'] =  index === 0, _obj['top-right-corner'] =  index === 4, _obj['right-item'] =  index === 4, _obj )},[_c('span',[_vm._v(_vm._s(title))]),(icon)?_c('lx-icon',{staticClass:"premium-icon",class:{ 'platinum-icon': index === 4 },attrs:{"size":"52,35","src":icon,"alt":title}}):_vm._e()],1)}),_vm._l((_vm.values),function(ref,index){
        var title = ref.title;
        var type = ref.type;
        var items = ref.items;
return [_c('div',{key:("item-title-" + index),staticClass:"item title",class:{
            'top-left-corner': index === 0,
            'bottom-left-corner': index === 4,
            'bottom-item': index === 4,
          }},[_vm._v(_vm._s(title))]),_vm._l((items),function(value,innerIndex){return _c('div',{key:("item-" + index + "-value-" + innerIndex),staticClass:"item value",class:{
            'right-item': innerIndex === items.length - 1,
            'bottom-right-corner': innerIndex === items.length - 1 && index === 4,
            'bottom-item': index === 4,
          }},[(type === 'amount')?_c('span',{staticClass:"time-amount text-no-wrap"},[_vm._v("\n            "+_vm._s(value)+"\n            "),_c('sup',{staticClass:"time"},[_vm._v(" TIME ")])]):(type === 'provider')?[(value)?_c('div',{staticClass:"checkbox-icon-container"},[_c('lx-icon',{staticClass:"checkbox-icon",attrs:{"icon":"circle-success","size":"20"}})],1):_c('div',{staticClass:"checkbox not-checked"})]:[_vm._v(_vm._s(value))]],2)})]})],2)]),_c('div',{staticClass:"mobile"},_vm._l((_vm.statuses),function(ref,index){
          var className = ref.className;
          var title = ref.title;
          var icon = ref.icon;
return _c('div',{key:("item-status-" + index),staticClass:"mobile-view advantages"},[_c('div',{key:("item-mobile-status-" + index),staticClass:"item header",class:className},[_c('span',[_vm._v(_vm._s(title))]),(icon)?_c('lx-icon',{staticClass:"premium-icon",class:{ 'platinum-icon': index === 4 },attrs:{"size":"25,17","src":icon,"alt":title}}):_vm._e()],1),_vm._l((_vm.values),function(ref,innerIndex){
          var title = ref.title;
          var type = ref.type;
          var items = ref.items;
return [_c('div',{key:("item-mobile-" + index + "-title-" + innerIndex),staticClass:"item title",class:{ 'bottom-item': innerIndex === 4 }},[_vm._v(_vm._s(title))]),_c('div',{key:("item-mobile-" + index + "-value-" + innerIndex),staticClass:"item value",class:{ 'bottom-item': innerIndex === 4 }},[(type === 'amount')?_c('span',{staticClass:"time-amount text-no-wrap"},[_vm._v("\n          "+_vm._s(items[index])+"\n          "),_c('sup',{staticClass:"time"},[_vm._v(" TIME ")])]):(type === 'provider')?[(items[index])?_c('div',{staticClass:"checkbox-icon-container"},[_c('lx-icon',{staticClass:"checkbox-icon",attrs:{"icon":"circle-success","size":"20"}})],1):_c('div',{staticClass:"checkbox not-checked"})]:[_vm._v(_vm._s(items[index]))]],2)]})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }